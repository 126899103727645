import {Controller} from '@hotwired/stimulus';

const LOG_PREFIX = 'Ajax form controller: ';

/**
 * This controller can be used to submit a form via AJAX and then re-render the whole element with the response.
 * The form submit button is automatically detected and the form is submitted when the button is clicked.
 * During form submit the submit button is also shown with "loading" animation.
 */
export default class extends Controller {

     connect() {
          this.boundClick = this.click.bind(this);
          this.element.addEventListener('click', this.boundClick);
     }

     async click(event) {
          const eventTarget = event.target;
          if (!eventTarget.matches('button[type="submit"]')) {
               return;
          }
          event.preventDefault();

          const formElement = this.element.querySelector('form');
          if (!formElement) {
               console.error(LOG_PREFIX + 'No form found in element', this.element);
               return;
          }

          if (!formElement.checkValidity()) {
               formElement.reportValidity();
               return;
          }

          try {
               this.buttonLoading(eventTarget);
               const response = await fetch(
                   formElement.action,
                   {
                        method: 'POST',
                        body: new FormData(formElement),
                   }
               );
               this.element.innerHTML = await response.text();
          } catch (error) {
               console.error(LOG_PREFIX + ' Form submit failed to ' + formElement.action, error);
               throw error;
          } finally {
               this.buttonNotLoading(eventTarget);
          }
     }

     disconnect() {
          this.element.removeEventListener('click', this.boundClick);
     }

     buttonLoading(button) {
          button.setAttribute('disabled', '');
          button.classList.remove('show--enabled');
          button.classList.add('is--loading');
     }

     buttonNotLoading(button) {
          button.classList.remove('show--enabled');
          button.removeAttribute('disabled', '');
          button.classList.remove('is--loading');
     }
}
